import type {RouteObject} from 'react-router-dom'
import type {DataRouterAppRegistrationFn} from '../react-app-registry'
import type {QueryRouteQueryType, QueryDepsFn, QueryRouteQueryConfig} from './data-router-types'
import {QueryRoute} from './query-route'

export class DataRouterApplication<T extends string> {
  declare readonly name: T
  #routes: RouteObject[]

  constructor(name: T, routes: RouteObject[]) {
    this.name = name
    this.#routes = routes
    this.registration = this.registration.bind(this)
  }

  registration(): ReturnType<DataRouterAppRegistrationFn> {
    return {
      routes: this.#routes,
    }
  }
}

const MAX_DEFINED_QUERIES_COUNT = 4

export class DataRouterApplicationBuilder<T extends string> {
  declare readonly name: T

  constructor(name: T) {
    this.name = name
  }

  createDataRouterAppFromRoutes(routes: RouteObject[]) {
    return new DataRouterApplication(this.name, routes)
  }

  static create<T extends string>(name: T) {
    return new DataRouterApplicationBuilder<T>(name)
  }

  // #region createQueryRouteConfig

  /**
   * createQueryRouteConfig is a complicated method that takes a variadic list of 'queries'
   * and converts them to a keyed object of queries, while also implementing a few additional
   * methods on the RouteConfig
   *
   * We have to defined a separate function header for _every query length_ in the tuple of queries
   * _yes, this is unideal_
   * but this is necessary to have typescript correctly handle inferring of all types across each of the
   * possible queries and merging them together for the output RouteConfig object.
   *
   * If you have a Route with more queries than we have defined here, you may need to extend the types to accommodate
   * that new entry! Reach out if you need help with this!
   *
   * {@see https://zyym.eu.org/github/github/pull/357701} For a sample PR for adding to the accepted list of query configs
   */

  /**
   * With no defined queries
   */
  createQueryRouteConfig<Id extends string, Path extends string>(
    /** Id must be a valid JavaScript camelCase Identifier */
    id: EnforceCamelCase<Id>,
    args: {
      path: Path
      index?: boolean
      queries?: []
    },
  ): {[x in Id]: QueryRoute<Id, Path, {}>}
  /**
   * With 1 defined query
   */
  createQueryRouteConfig<
    Id extends string,
    Path extends string,
    /** Query 1 */
    Query1Name extends string,
    Query1Deps extends QueryDepsFn<Path>,
    Query1Res,
    Query1Type extends QueryRouteQueryType,
  >(
    /** Id must be a valid JavaScript camelCase Identifier */
    id: EnforceCamelCase<Id>,
    args: {
      path: Path
      index?: boolean
      queries: [QueryRouteQueryConfig<Path, Query1Name, Query1Deps, Query1Res, Query1Type>]
    },
  ): {
    [x in Id]: QueryRoute<
      Id,
      Path,
      {
        [K in Query1Name]: QueryRouteQueryConfig<Path, Query1Name, Query1Deps, Query1Res, Query1Type>
      }
    >
  }

  /**
   * With 2 defined queries
   */
  createQueryRouteConfig<
    Id extends string,
    Path extends string,
    /** Query 1 */
    Query1Name extends string,
    Query1Deps extends QueryDepsFn<Path>,
    Query1Res,
    Query1Type extends QueryRouteQueryType,
    /** Query 2 */
    Query2Name extends string,
    Query2Deps extends QueryDepsFn<Path>,
    Query2Res,
    Query2Type extends QueryRouteQueryType,
  >(
    /** Id must be a valid JavaScript camelCase Identifier */
    id: EnforceCamelCase<Id>,
    args: {
      path: Path
      index?: boolean
      queries: [
        QueryRouteQueryConfig<Path, Query1Name, Query1Deps, Query1Res, Query1Type>,
        QueryRouteQueryConfig<Path, Query2Name, Query2Deps, Query2Res, Query2Type>,
      ]
    },
  ): {
    [x in Id]: QueryRoute<
      Id,
      Path,
      {
        [K in Query1Name]: QueryRouteQueryConfig<Path, Query1Name, Query1Deps, Query1Res, Query1Type>
      } & {
        [K in Query2Name]: QueryRouteQueryConfig<Path, Query2Name, Query2Deps, Query2Res, Query2Type>
      }
    >
  }

  /**
   * With 3 defined queries
   */
  createQueryRouteConfig<
    Id extends string,
    Path extends string,
    /** Query 1 */
    Query1Name extends string,
    Query1Deps extends QueryDepsFn<Path>,
    Query1Res,
    Query1Type extends QueryRouteQueryType,
    /** Query 2 */
    Query2Name extends string,
    Query2Deps extends QueryDepsFn<Path>,
    Query2Res,
    Query2Type extends QueryRouteQueryType,
    /** Query 3 */
    Query3Name extends string,
    Query3Deps extends QueryDepsFn<Path>,
    Query3Res,
    Query3Type extends QueryRouteQueryType,
  >(
    /** Id must be a valid JavaScript camelCase Identifier */
    id: EnforceCamelCase<Id>,
    args: {
      path: Path
      index?: boolean
      queries: [
        QueryRouteQueryConfig<Path, Query1Name, Query1Deps, Query1Res, Query1Type>,
        QueryRouteQueryConfig<Path, Query2Name, Query2Deps, Query2Res, Query2Type>,
        QueryRouteQueryConfig<Path, Query3Name, Query3Deps, Query3Res, Query3Type>,
      ]
    },
  ): {
    [x in Id]: QueryRoute<
      Id,
      Path,
      {
        [K in Query1Name]: QueryRouteQueryConfig<Path, Query1Name, Query1Deps, Query1Res, Query1Type>
      } & {
        [K in Query2Name]: QueryRouteQueryConfig<Path, Query2Name, Query2Deps, Query2Res, Query2Type>
      } & {
        [K in Query3Name]: QueryRouteQueryConfig<Path, Query3Name, Query3Deps, Query3Res, Query3Type>
      }
    >
  }
  /**
   * With 4 defined queries
   */
  createQueryRouteConfig<
    Id extends string,
    Path extends string,
    /** Query 1 */
    Query1Name extends string,
    Query1Deps extends QueryDepsFn<Path>,
    Query1Res,
    Query1Type extends QueryRouteQueryType,
    /** Query 2 */
    Query2Name extends string,
    Query2Deps extends QueryDepsFn<Path>,
    Query2Res,
    Query2Type extends QueryRouteQueryType,
    /** Query 3 */
    Query3Name extends string,
    Query3Deps extends QueryDepsFn<Path>,
    Query3Res,
    Query3Type extends QueryRouteQueryType,
    /** Query 4 */
    Query4Name extends string,
    Query4Deps extends QueryDepsFn<Path>,
    Query4Res,
    Query4Type extends QueryRouteQueryType,
  >(
    /** Id must be a valid JavaScript camelCase Identifier */
    id: EnforceCamelCase<Id>,
    args: {
      path: Path
      index?: boolean
      queries: [
        QueryRouteQueryConfig<Path, Query1Name, Query1Deps, Query1Res, Query1Type>,
        QueryRouteQueryConfig<Path, Query2Name, Query2Deps, Query2Res, Query2Type>,
        QueryRouteQueryConfig<Path, Query3Name, Query3Deps, Query3Res, Query3Type>,
        QueryRouteQueryConfig<Path, Query4Name, Query4Deps, Query4Res, Query4Type>,
      ]
    },
  ): {
    [x in Id]: QueryRoute<
      Id,
      Path,
      {
        [K in Query1Name]: QueryRouteQueryConfig<Path, Query1Name, Query1Deps, Query1Res, Query1Type>
      } & {
        [K in Query2Name]: QueryRouteQueryConfig<Path, Query2Name, Query2Deps, Query2Res, Query2Type>
      } & {
        [K in Query3Name]: QueryRouteQueryConfig<Path, Query3Name, Query3Deps, Query3Res, Query3Type>
      } & {
        [K in Query4Name]: QueryRouteQueryConfig<Path, Query4Name, Query4Deps, Query4Res, Query4Type>
      }
    >
  }

  /**
   * Implementation
   */
  createQueryRouteConfig<Id extends string, Path extends string>(
    /** Id must be a valid JavaScript camelCase Identifier */
    id: Id,
    {
      path,
      index,
      queries = [],
    }: {
      path: Path
      index?: boolean
      queries?:
        | []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        | [QueryRouteQueryConfig<any, any, any, any, any>]
        | [
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            QueryRouteQueryConfig<any, any, any, any, any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            QueryRouteQueryConfig<any, any, any, any, any>,
          ]
        | [
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            QueryRouteQueryConfig<any, any, any, any, any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            QueryRouteQueryConfig<any, any, any, any, any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            QueryRouteQueryConfig<any, any, any, any, any>,
          ]
        | [
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            QueryRouteQueryConfig<any, any, any, any, any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            QueryRouteQueryConfig<any, any, any, any, any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            QueryRouteQueryConfig<any, any, any, any, any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            QueryRouteQueryConfig<any, any, any, any, any>,
          ]
    },
  ) {
    assertQueriesWithinLimit(queries)
    assertCamelCase(id)

    return {
      [id]: new QueryRoute({id, path, queries: toKeyedQueries(queries), index: index ?? false, appName: this.name}),
    }
  }

  // #endregion createQueryRouteConfig
}

function assertQueriesWithinLimit<T>(arr: T[]) {
  if (arr.length > MAX_DEFINED_QUERIES_COUNT) throw new InvalidNumberOfQueryConfigsError(arr.length)
}

class DuplicateRouteQueryNameError extends Error {
  constructor(queryName: string) {
    super(`query names cannot be duplicated: \`${queryName}\` has already been defined for this route.`)
    this.name = 'DuplicateRouteQueryNameError'
  }
}

function toKeyedQueries<
  T extends
    | []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | [QueryRouteQueryConfig<any, any, any, any, any>]
    | [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        QueryRouteQueryConfig<any, any, any, any, any>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        QueryRouteQueryConfig<any, any, any, any, any>,
      ]
    | [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        QueryRouteQueryConfig<any, any, any, any, any>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        QueryRouteQueryConfig<any, any, any, any, any>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        QueryRouteQueryConfig<any, any, any, any, any>,
      ]
    | [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        QueryRouteQueryConfig<any, any, any, any, any>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        QueryRouteQueryConfig<any, any, any, any, any>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        QueryRouteQueryConfig<any, any, any, any, any>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        QueryRouteQueryConfig<any, any, any, any, any>,
      ],
>(queries: T) {
  const registeredQueryNames = new Set<string>()

  return Object.fromEntries(
    queries.map(({queryName, ...config}) => {
      if (registeredQueryNames.has(queryName)) {
        throw new DuplicateRouteQueryNameError(queryName)
      }
      registeredQueryNames.add(queryName)
      return [queryName, config] as const
    }),
  )
}

class InvalidNumberOfQueryConfigsError extends Error {
  constructor(queriesLength: number) {
    super(
      `Invalid number of query configs error. ${queriesLength} queries supplied of a max ${MAX_DEFINED_QUERIES_COUNT} queries allowed.`,
    )
    this.name = 'InvalidNumberOfQueryConfigsError'
  }
}

type IsCamelCase<T extends string> = T extends `${infer First}${infer Rest}`
  ? First extends Lowercase<First> // The first character must be lowercase
    ? Rest extends '' // If the remaining string is empty, it's valid (just a single lowercase letter)
      ? true
      : Rest extends `${infer SecondLetter}${infer Remaining}` // Any subsequent characters
        ? SecondLetter extends Uppercase<SecondLetter> // If we encounter an uppercase letter, it's allowed
          ? IsCamelCase<Remaining> // Continue validating after finding an uppercase
          : Rest extends `${infer NonAlphanumeric}${infer _}` // Disallow non-alphanumeric characters
            ? NonAlphanumeric extends
                | Lowercase<NonAlphanumeric>
                | Uppercase<NonAlphanumeric>
                | '0'
                | '1'
                | '2'
                | '3'
                | '4'
                | '5'
                | '6'
                | '7'
                | '8'
                | '9'
              ? true
              : false // If a special character is encountered, it's invalid
            : true // Continue if no non-alphanumeric characters are found
        : true // For edge cases (like a single lowercase letter)
    : false // If the first character is not lowercase, it's invalid
  : false // An empty string is invalid

// Special case for handling special characters explicitly
type HasSpecialChars<T extends string> = T extends `${infer First}${infer Rest}`
  ? First extends '-' | '_' | '!' | '@' | '#' | '$' | '%' | '^' | '&' | '*' | '(' | ')' // List of special characters
    ? true
    : HasSpecialChars<Rest> // Continue checking the remaining part
  : false // Empty string is valid

type EnforceCamelCase<T extends string> = IsCamelCase<T> extends true
  ? HasSpecialChars<T> extends true
    ? never
    : T
  : never

function assertCamelCase(str: string) {
  // Regular expression to validate camel case
  const camelCasePattern = /^[a-z][a-zA-Z0-9]*$/
  if (!camelCasePattern.test(str)) throw new InvalidIdentifierError(str)
}

export class InvalidIdentifierError extends Error {
  constructor(str: string) {
    super(`\`${str}\` must be camel cased`)
    this.name = 'InvalidIdentifierError'
  }
}
